import { fetchData } from "../../services/api";

export const getTextRaLevels = async (sectionID: string): Promise<string> => {
    try {
        const data = await fetchData("StreetParking/sections/geoJson", "GET", "url", {
            sectionID: sectionID,
        });

        return data.length > 0 ? [data[0].raLevel2, data[0].raLevel3].filter(Boolean).join('; ') : '';
    } catch (error) {
        console.error("Failed to fetch RA levels:", error);
        return '';
    }
};
