type Options = {
    method: string;
    headers: any;
    body?: string;
}

const apiToken = process.env.REACT_APP_API_FORCE_TOKEN || null;
const apiUrl = process.env.REACT_APP_API_FORCE_URL || '/api/v1';

export const fetchData = async (url: string, method: string = "GET", paramsType: string = "body", params?: any, jsonBody:boolean = true): Promise<any> => {
    let paramsFormatted: string = "";
    let paramsUrl:string = "";
    if(paramsType === "body"){
        if(jsonBody === true) paramsFormatted = JSON.stringify(params ?? {});
        else paramsFormatted = params;
    }else if (paramsType === "url"){
        paramsUrl = new URLSearchParams(params).toString();
    }

    try {
        let options:Options = {
            method: method,
            headers: {
                Accept: 'application/json',
                "apiKey": apiToken,
            }
        };

        if (method === "POST"){
            options.body = paramsFormatted;
            options.headers["Content-Type"] = "application/json";
        }

        const response = await fetch(`${apiUrl}/${url}?${paramsUrl}`, options);
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const contentType = response.headers.get('Content-Type');

        if (contentType && contentType === 'application/pdf') {
            return await response.blob();
        }

        return await response.json();
    } catch (error) {
        handleApiError(error);
        return;
    }
};

const handleApiError = (error: any): void => {
    console.error('Fetch Error:', error.message);
};
