import {useIndexedDB} from "react-indexed-db-hook";
import {useEffect, useState} from "react";

export default function useSettingsState() {
	const dbValues = useIndexedDB("settings");
	const { getAll, add, deleteRecord, getByIndex: _getByIndex, clear: clearAll, getByID} = dbValues;

	const [settings, setSettings] = useState([]);
	useEffect(() => {
		getAll()
			.then((valuesFromDB) => {
				setSettings(valuesFromDB);
			})
			.catch(() => setSettings([]));
	}, [dbValues]);

	const getByIndex = (indexName: string, key: any) => {
		if (indexName === 'id') {
			key = parseInt(key);
		}
		return settings.find(settings => settings[indexName] === key);
	}

	const getSettingsValue = (value: any) => {
		return settings.find(settings => settings['key'] === value);
	}

	const deleteByIndex = (indexName: string, key: any) => {
		if (indexName === 'id') {
			return deleteRecord(parseInt(key))
		} else {
			return _getByIndex(indexName, key).then((entity) => deleteRecord(entity.id));
		}
	}

	return {settings, add, deleteRecord, getByIndex, getByIndexAsync: _getByIndex, deleteByIndex, clearAll, getSettingsValue};
}