import {fetchData} from "../../services/api";
import {Debugger} from "./DebuggerHelper";

const validateMerchantApple = async (json) => {
    const response = await fetchData("Payment/ApplePay/MerchantSession", "POST", "body", {
        "validationURL": json.validationURL
    });
    return response;
}

const generateUUID = () => {
    let
        d = new Date().getTime(),
        d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0;
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
        let r = Math.random() * 16;
        if (d > 0) {
            r = (d + r) % 16 | 0;
            d = Math.floor(d / 16);
        } else {
            r = (d2 + r) % 16 | 0;
            d2 = Math.floor(d2 / 16);
        }
        return (c == 'x' ? r : (r & 0x7 | 0x8)).toString(16);
    });
};

const paymentGWBrowserData = {
        "acceptHeader": "text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.7",
        "javaEnabled": false,
        "language": navigator.language,
        "colorDepth": window.screen.colorDepth,
        "screenHeight": window.innerHeight,
        "screenWidth": window.innerWidth,
        "timezone": new Date().getTimezoneOffset(),
        "userAgent": window.navigator.userAgent,
        "javascriptEnabled": true
};

export const tokenPaymentProcess = async (ticket, token, navigate = null) => {
    let json = await fetchData("Payment/CustomerTokenPayment/Process", "POST", "body", {
        "ticketGUID": ticket,
        "paymentGWToken": token,
        "paymentGWBrowserData": paymentGWBrowserData
    });

    if(navigate && json.paymentSuccessful === true) {
        navigate(`/paid/${json.ticket.ticketGUID}`);
    }

    if (json.actions) {
        if (json.actions?.authenticate.browserChallenge.url) window.location.href = json.actions.authenticate.browserChallenge.url;
    }

    return json;
}

export const paymentProcess = async (type, ticket, navigate = null) => {
    let json = await fetchData(`Payment/${type}/Process`, "POST", "body", {
        "ticketGUID": ticket,
        "browser": paymentGWBrowserData
    });

    if(navigate && json.paymentSuccessful === true) {
        navigate(`/paid/${json.ticket.ticketGUID}`);
    }

    if(json.actions?.authenticate.browserChallenge.url) window.location.href = json.actions.authenticate.browserChallenge.url;

    return json;
}

export const orderTicket = async (
    featureType,
    lang,
    licencePlate,
    email,
    requestedMinutes,
    paymentMethod,
    parkingZone = null,
    paymentToken = null,
    addTokenSession = null,
    price = null,
    navigate = null
) => {
    Debugger.start();
    if (!paymentMethod || !parkingZone) {
        return;
    }

    let session:any;

    const generateReturnUrl = (method, randomUUID = null) => {
        const base = `${window.location.protocol}//${window.location.host}/payment`;
        switch (method) {
            case 'Card': return `${base}/process`;
            case 'CardToken': return `${base}/token/${randomUUID}`;
            case 'ApplePay': return `${base}/applepay/${randomUUID}`;
            case 'GooglePay': return `${base}/googlepay/${randomUUID}`;
            default: return base;
        }
    };

    const processApplePay = async () => {
        if (!PaymentRequest) {
            return;
        }

        try {
            const paymentMethodData = [{
                "supportedMethods": "https://apple.com/apple-pay",
                "data": {
                    "version": 3,
                    "merchantIdentifier": "merchant.eu.praha.parkingportal",
                    "merchantCapabilities": [
                        "supports3DS"
                    ],
                    "supportedNetworks": [
                        "amex",
                        "discover",
                        "masterCard",
                        "visa"
                    ],
                    "countryCode": "CZ"
                }
            }];

            const paymentDetails = {
                "total": {
                    "label": "Parkování",
                    "amount": {
                        "value": Number(price),
                        "currency": "CZK"
                    }
                }
            };

            const paymentOptions = {
                "requestPayerName": false,
                "requestBillingAddress": false,
                "requestPayerEmail": false,
                "requestPayerPhone": false,
                "requestShipping": false,
            };

            // @ts-ignore
            const request = new PaymentRequest(paymentMethodData, paymentDetails, paymentOptions);
            // @ts-ignore
            request.onmerchantvalidation = async event => {
                const merchantSessionPromise = await validateMerchantApple(event);
                event.complete(merchantSessionPromise);
            };

            // @ts-ignore
            request.onpaymentmethodchange = event => {
                // @ts-ignore
                if (event.methodDetails.type !== undefined) {
                    const paymentDetailsUpdate = {
                        'total': paymentDetails.total
                    };
                    // @ts-ignore
                    event.updateWith(paymentDetailsUpdate);
                }
            };

            const response = await request.show();
            await response.complete("success");
            session = btoa(JSON.stringify(response.details.token.paymentData));
        } catch (e) {

        }
    }

    const processGooglePay = async () => {
        try {
            const environment = process.env.NODE_ENV === 'development' ? 'TEST' : 'PRODUCTION';
            const paymentsClient = new google.payments.api.PaymentsClient({ environment });

            const paymentRequest: google.payments.api.PaymentDataRequest = {
                apiVersion: 2,
                apiVersionMinor: 0,
                allowedPaymentMethods: [
                    {
                        type: 'CARD',
                        parameters: {
                            allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
                            allowedCardNetworks: ['MASTERCARD', 'VISA'],
                        },
                        tokenizationSpecification: {
                            type: 'PAYMENT_GATEWAY',
                            parameters: {
                                gateway: process.env.REACT_APP_GOOGLE_PAY_GATEWAY,
                                gatewayMerchantId: process.env.REACT_APP_GOOGLE_PAY_GATEWAY_MERCHANT_ID,
                            },
                        },
                    },
                ],
                merchantInfo: {
                    merchantId: process.env.REACT_APP_GOOGLE_PAY_MERCHANT_ID,
                    merchantName: process.env.REACT_APP_GOOGLE_PAY_MERCHANT_COMPANY_NAME,
                },
                transactionInfo: {
                    totalPriceStatus: 'FINAL',
                    totalPrice: Number(price).toFixed(2),
                    currencyCode: 'CZK',
                    countryCode: 'CZ',
                },
            };

            const paymentData = await paymentsClient.loadPaymentData(paymentRequest);
            session = btoa(JSON.stringify(paymentData.paymentMethodData.tokenizationData.token));

            Debugger.log(paymentRequest);
            Debugger.log(paymentData);
            Debugger.log(session);
        } catch (error) {
            Debugger.error("Google Pay error:", error)
        }
    };

    if (paymentMethod === 'ApplePay') await processApplePay();
    if (paymentMethod === 'GooglePay') await processGooglePay();

    let returnUrl, randomUUID;

    randomUUID = generateUUID();
    returnUrl = generateReturnUrl(paymentMethod, randomUUID);

    let params = {
        licensePlate: licencePlate.trim(),
        ...(email ? { email: email.trim() } : { email: 'parkovani@operatorict.cz' }), //dummy email, pokud není vyplněno
        ...(featureType === "pz" ? { sectionCode: parkingZone?.sectionCode } : {}),
        ...(featureType === "pm" ? { parkMachineCode: parkingZone?.parkMachineCode } : {}),
        ...(featureType === "ib" ? { parkMachineCode: parkingZone?.parkMachineCode } : {}),
        requestedMinutes: Number(requestedMinutes),
        ticketPayment: {
            paymentMethod: paymentMethod,
            createToken: true,
            paymentGWToken: paymentToken,
            paymentGWBrowserData: paymentGWBrowserData,
            paymentGWReturnURL: returnUrl,
            sdkUsed: false,
            paymentData: session
        },
        language: lang ?? "cs"
    };

    Debugger.log(params);

    return await fetchData("Tickets/streetParking", "POST", "body", params).then((data) => {
        if (!data) return {success: false};
        Debugger.log(data);

        if (data.success === true && data.ticket.priceTotal === 0) {
            navigate(`/payment/zp/${data.ticket.ticketGUID}`);
        }

        if (paymentMethod === 'Card') window.location.href = data.paymentGWRedirectURL;
        if (paymentMethod === 'CardToken') {
            addTokenSession({
                "uuid": randomUUID,
                "ticketGUID": data.ticket.ticketGUID,
                "paymentGWReference": data.paymentGWReference
            });
            tokenPaymentProcess(data.ticket.ticketGUID, data.paymentGWReference, navigate);
            setInterval(() => {
                tokenPaymentProcess(data.ticket.ticketGUID, data.paymentGWReference, navigate)
            }, 2000)
        }
        if (paymentMethod === 'ApplePay' || paymentMethod === 'GooglePay') {
            addTokenSession({
                "uuid": randomUUID,
                "ticketGUID": data.ticket.ticketGUID,
                "paymentGWReference": null
            });
            paymentProcess(paymentMethod, data.ticket.ticketGUID, navigate);
            setInterval(() => {
                paymentProcess(paymentMethod, data.ticket.ticketGUID, navigate)
            }, 2000)
        }
    });
}
