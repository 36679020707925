import styles from './DetailZoneActions.module.scss';
import {useTranslation} from "react-i18next";
import {Link, useNavigate} from "react-router-dom";
import Icon from "../Icon";
import useFavoriteZone from "../../model/useFavoriteZone";
import {useEffect, useState} from "react";
import {PARK_MACHINE_TYPE_FAKE} from "../Map";

type Props = {
    sectionCode: string,
    section: any,
    sectionId: string,
    featureType: "parkingZone"|"parkingMachine"
    properties: any
}

const DetailZoneActions = ({sectionCode, section, sectionId, featureType, properties}: Props) => {
    const {t} = useTranslation();
    const navigate = useNavigate();

    const { zones, add, deleteByIndex } = useFavoriteZone();

    useEffect(() => {
        const zone = zones.find(zone => zone.id === sectionId);
        setIsFavorite(zone);
    }, [zones]);

    const [isFavorite, setIsFavorite] = useState<boolean>(false);

    const handleFavorite = () => {
        if (!isFavorite) {
            add({"id": sectionId, "json": JSON.stringify(section)});
        } else {
            deleteByIndex("id", sectionId);
        }
    }

    const buyShort = featureType === "parkingZone"
        ? "pz"
        : (properties.parkMachineType === PARK_MACHINE_TYPE_FAKE ? 'ib' : 'pm');

    return (
        <div className={styles.actions}>
            <button className={`${styles.addToFavorite} ${isFavorite ? styles.active : ""}`} onClick={handleFavorite}>
                <span>
                    <Icon.Star size={18} color={isFavorite ? "#FFFFFF" : "#1D4F8B"} />
                </span>
            </button>

            { section?.tariffInfo?.maxHour > 0 && (
                    <Link to={`/buy/${buyShort}/${sectionCode}`} className={`${styles.buyTicket}`}>
                        <Icon.ShoppingBasket size={16} color={"#FFFFFF"} />
                        &nbsp;&nbsp;{t("home.DetailZoneActionsComponent.buy")}
                    </Link>
                )
            }
        </div>
    );
};

export default DetailZoneActions;