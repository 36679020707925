import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout";
import Page404 from "./pages/Page404";
import Main from "./pages/Main";
import Buy from "./pages/Buy";
import Settings from "./pages/Settings";
import Paid from "./pages/Paid";
import ExtendParking from "./pages/ExtendParking";
import ParkingSession from "./pages/ParkingSession";
import NewVehicle from "./pages/Save/NewVehicle";
import RemoveVehicle from "./pages/Save/RemoveVehicle";
import RemoveCard from "./pages/Save/RemoveCard";
import ProcessPaymentByCard from "./pages/Payment/ProcessPaymentByCard";
import NewEmail from "./pages/Save/NewEmail";
import RemoveEmail from "./pages/Save/RemoveEmail";
import MapboxTest from "./pages/MapboxTest";
import {PreviousLocationProvider} from "./hooks/PreviousLocationContext";
import Gdpr from "./pages/Gdpr";
import Cookies from "./pages/Cookies";
import {Helmet} from "react-helmet-async";

const websiteUrl = process.env.REACT_APP_WEB_URL;

export default function App() {

	return (

        <>
            <Helmet>
                 <script defer data-domain={websiteUrl} src="https://plausible.io/js/script.js"></script>
            </Helmet>
			<BrowserRouter>
				<PreviousLocationProvider>
					<Routes>
						<Route path="/" element={<Layout />}>
							<Route index element={<Main />} />
							<Route path={'/pz/:sectionCode'} index element={<Main />} />
							<Route path={'/ib/:sectionCode'} index element={<Main />} />
							<Route path={'/pm/:sectionCode'} index element={<Main />} />

							{/* Testovací mapa */}
							<Route path={'/mapbox'} element={<MapboxTest />} />

							<Route path={'/buy/:featureType/:sectionCode'} element={<Buy />} />

							<Route path={'/payment/process'} element={<ProcessPaymentByCard />} />
							<Route path={'/payment/zp/:token'} element={<ProcessPaymentByCard zeroPay={true}/>} />
							<Route path={'/payment/token/:token'} element={<ProcessPaymentByCard token={true}/>} />
							<Route path={'/payment/applepay/:token'} element={<ProcessPaymentByCard applepay={true}/>} />
							<Route path={'/payment/googlepay/:token'} element={<ProcessPaymentByCard googlepay={true}/>} />

							<Route path={'/settings'} element={<Settings />} />
							<Route path={'/settings/email/new'} element={<NewEmail />} />
							<Route path={'/settings/vehicle/new'} element={<NewVehicle />} />
							<Route path={'/settings/vehicle/remove/:vehicleId'} element={<RemoveVehicle />} />
							<Route path={'/settings/email/remove/:emailId'} element={<RemoveEmail />} />
							<Route path={'/settings/card/remove/:cardId'} element={<RemoveCard />} />

							<Route path={'/gdpr'} element={<Gdpr />} />

							<Route path={'/cookies'} element={<Cookies />} />

							{/* TODO: Přidat asi parametr z API a načíst si potřebný věci */}
							<Route path={'/paid/:ticketGUID'} element={<Paid />} />
							<Route path={'/extend-parking/:ticketGUID'} element={<ExtendParking />} />
							<Route path={'/parking-session/:ticketGUID'} element={<ParkingSession />} />
							<Route path={'*'} element={<Page404 />} />
						</Route>
					</Routes>
				</PreviousLocationProvider>
			</BrowserRouter>
        </>

	);
}
