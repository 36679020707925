import styles from './ParkingSessionItem.module.scss';
import Icon from "../Icon";
import ProgressBar from "../ProgressBar/ProgressBar";
import dayjs from "dayjs";
import {Link, useNavigate} from "react-router-dom";
import relativeTime from 'dayjs/plugin/relativeTime';
import duration from 'dayjs/plugin/duration';
import {t} from "i18next";
import React, { useState, useEffect } from 'react';
import useVehiclesState from "../../model/useVehiclesState";
import useParkingSession from "../../model/useParkingSession";
import {getTicket} from "../../model/helpers/Session";

type Props = {
    from: string,
    to: string,
    ticketGUID: string,
    carPlate: string
};

const ParkingSessionItem: React.FCC<Props> = ({...props}) => {

    dayjs.extend(relativeTime)
    dayjs.extend(duration)

    const navigate = useNavigate();

    const { getByIndexAsync: getVehicleByIndexAsync} = useVehiclesState();

    const [carPlate, setCarPlate] = useState(null);

    useEffect(() => {
        getVehicleByIndexAsync("spz", props.carPlate).then((vehicle) => {
            if (vehicle) {
                setCarPlate(vehicle.name);
            }
        })
    }, []);

    const [ticketData, setTicketData] = useState(null);
    const [featureCode, setFeatureCode] = useState(null);
    const [featureType, setFeatureType] = useState(null);
    useEffect(() => {
        getTicket(props.ticketGUID, (data: any) => {
            setTicketData(data);
            if (data.sectionCode !== null) {
                setFeatureCode(data.sectionCode);
                setFeatureType('pz');
            } else {
                setFeatureCode(data.parkMachineCode);
                setFeatureType('pm');
            }
        })
    }, [props.ticketGUID]);

    return (
        <div className={styles.item}
             onClick={() => navigate(`/paid/${props.ticketGUID}`)}>
            <div className={styles.info}>
                <div className={styles.icon}>
                    <Icon.Car size={22} color={"#1D4F8B"} />
                </div>
                <div className={styles.title}>
                    <div className={styles.remaining}>
                        {getTimeUntil(props.to, props.ticketGUID)}
                    </div>
                    <div className={styles.parkingInfo}>
                        {carPlate ?? props.carPlate}
                    </div>
                </div>
                {featureType && featureCode && ticketData?.licensePlate && (
                    <div className={styles.actions}>
                        <Link
                            to={`/buy/${featureType}/${featureCode}/`}
                            className={`${styles.buyButton}`}
                            onClick={(e) => e.stopPropagation()}
                        >
                            {t("home.parkingSessionItem.extend")}
                        </Link>
                    </div>
                )}
                <div className={styles.openIcon}>
                    <Icon.CaretRight size={16} color={"#1D4F8B"} />
                </div>
            </div>
            <ProgressBar onlyBar={true} orderDate={dayjs(props.from).toDate()}
                         endDate={dayjs(props.to).toDate()}></ProgressBar>
        </div>
    );
}

export default ParkingSessionItem;


const getTimeUntil = (targetDateTime: string, ticketGUID) => {
    const now = dayjs();
    const targetTime = dayjs(targetDateTime);
    const {deleteByIndex} = useParkingSession();


    if (targetTime.isBefore(now)) {
        deleteByIndex("guid", ticketGUID);
        return t("progressBarComponent.end");
    }

    const duration = dayjs.duration(targetTime.diff(now));

    const hours = duration.hours();
    const minutes = duration.minutes();
    const seconds = duration.seconds();

    let txt = "";

    if(hours > 0) txt += `${hours} `;
    if(hours === 1) txt += t("global.timeFull.hour1") + " ";
    if(hours >= 2 && hours <= 4) txt += t("global.timeFull.hour2_4") + " ";
    if(hours > 4) txt += t("global.timeFull.hour5+") + " ";

    if(minutes > 0) txt += `${minutes} `;
    if(minutes === 1) txt += t("global.timeFull.minute1")
    if(minutes >= 2 && minutes <= 4) txt += t("global.timeFull.minute2_4")
    if(minutes > 4) txt += t("global.timeFull.minute5+")

    if (minutes === 0) {
        txt += `${seconds} `;
        if (seconds === 1) txt += t("global.timeFull.second1")
        if (seconds >= 2 && seconds <= 4) txt += t("global.timeFull.second2_4")
        if (seconds > 4 || seconds === 0) txt += t("global.timeFull.second5+")
    }

    return txt;
}