import React from 'react';
import styles from './Footer.module.scss';

import facebookWhite from '../../assets/images/facebook_white.svg';
import xWhite from '../../assets/images/x_white.svg';
import rssWhite from '../../assets/images/rss_white.svg';

import pragueLogo from '../../assets/images/prague_logo.svg';
import tskLogo from '../../assets/images/tsk_logo.svg';

type Props = {

}

const Footer = ({ ...props }: Props) => {
    const date = new Date();

	return (
        <div className={`d-flex flex-column ${styles.footer}`}>
            <div className={`container ${styles.footerWrapper}`}>
                <div className={styles.leftSide}>
                    <div className={styles.links}>
                        <a className={styles.link} target={'_blank'} href={'https://parking.praha.eu/cs/moznosti-parkovani-v-praze/'}>Parking.praha.eu</a>
                        <a className={styles.link} href={'/gdpr'}>Zpracování osobních údajů</a>
                        <a className={styles.link} href={'/cookies'}>Cookies</a>
                    </div>
                    <div className={styles.bottomText}>
                        Technická správa komunikací hlavního města Prahy, a.s. je správcem systému zón placeného stání.
                    </div>
                </div>
                <div className={styles.rightSide}>
                    <div className={styles.followContainer}>
                        <span>Sledujte nás</span>
                        <a className={styles.followLink} target={'_blank'} href={'https://www.facebook.com/TSKPraha/'}>
                            <img src={facebookWhite} />
                        </a>
                        <a className={styles.followLink} target={'_blank'} href={'https://x.com/tskpraha'}>
                            <img src={xWhite} />
                        </a>
                        <a className={styles.followLink} target={'_blank'} href={'https://parking.praha.eu/cs/feed/'}>
                            <img src={rssWhite} />
                        </a>
                    </div>
                    <div className={styles.logos}>
                        <a className={styles.logoLink} target={'_blank'} href={'https://praha.eu'}>
                            <img src={pragueLogo} />
                        </a>
                        <a className={styles.logoLink} target={'_blank'} href={'https://www.tsk-praha.cz'}>
                            <img src={tskLogo} />
                        </a>
                    </div>
                </div>
            </div>
            <div className={`container ${styles.copyright}`}>&copy;&nbsp;{date.getFullYear()}</div>
        </div>
	);
};

export default Footer;
