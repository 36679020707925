import React, {useEffect, useState} from 'react';
import styles from './Layout.module.scss';
import logo from '../assets/images/logo.svg';
import logoEn from '../assets/images/logo-en.svg';

import menuIcon from '../assets/images/menu.svg';
import accountIcon from '../assets/images/acount.svg';
import {Link, Outlet, useLocation} from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Icon from "../components/Icon";
import LanguageSwitcher from "../components/LanguageSwitcher/LanguageSwitcher";
import 'assets/scss/global.scss';
import {useTranslation} from "react-i18next";
import useSettingsState from "../model/useSettingsState";
import Cookies from "js-cookie";
import useParkingSession from "../model/useParkingSession";
import useBuyFormValues from "../model/useBuyFormValues";
import useTokenSessionState from "../model/useTokenSessionState";
import Footer from "../components/Footer/Footer";
import i18n from "i18next";
import AcceptCookies from "../components/AcceptCookies/AcceptCookies";

type Props = {

};

const Layout: React.FCC<Props> = ({...props}) => {
	const [mobileNavbarOpened, setMobileNavbarOpened] = useState(false);
	const navigate = useNavigate();

	const handleMobileMenuClick = () => {
		setMobileNavbarOpened(false);
	};

	const getSidePanelClass = () => {
		if (window.location.pathname === '/' || window.location.pathname.startsWith("/pz/") || window.location.pathname.startsWith("/pm/") || window.location.pathname.startsWith("/ib/")) {
			return `${styles.sidePanel} ${styles.sidePanelMap}`;
		}

		return styles.sidePanel;
	};

	const {t} = useTranslation();

	const { getSettingsValue, add: addSettingsValue, settings, deleteRecord: deleteSettingsValue} = useSettingsState();
	const { clearAll: clearAllParkingSession } = useParkingSession();
	const { clearAll: clearAllBuyFormValues } = useBuyFormValues();
	const { clearAll: clearTokenSessionState } = useTokenSessionState();

	const [deployment, setDeployment] = useState(null);

	useEffect(() => {
		fetch('/deploy.txt')
			.then(response => response.text())
			.then(text => setDeployment(text.trim()))
	}, []);

	const [isAppVersionSaved, setIsAppVersionSaved] = useState(false);
	const [isSettingsLoaded, setIsSettingsLoaded] = useState(false);

	useEffect(() => {
		if (settings && Object.keys(settings).length > 0) {
			setIsSettingsLoaded(true);
		}
	}, [settings]);

	useEffect(() => {
		const checkAndSaveAppVersion = async (isLoaded) => {
			try {
				const deployVersion = process.env.REACT_APP_VERSION + "-" + deployment;
				const appVersion = await getSettingsValue('appVersion');

				if (!appVersion && isLoaded) {
					await addSettingsValue({
						key: 'appVersion',
						value: deployVersion
					});
					setIsAppVersionSaved(true);
				} else if (appVersion?.value !== deployVersion && isLoaded) {
					await deleteSettingsValue('appVersion');
					await addSettingsValue({
						key: 'appVersion',
						value: deployVersion
					});
					setIsAppVersionSaved(true);

					Cookies.remove("lang");
					await clearAllParkingSession();
					await clearAllBuyFormValues();
					await clearTokenSessionState();
				}
			} catch (error) {
				console.error("Error saving app version:", error);
			}
		};

		if (deployment && isSettingsLoaded && !isAppVersionSaved) {
			checkAndSaveAppVersion(isSettingsLoaded);
		} else if (deployment) {
			checkAndSaveAppVersion(isSettingsLoaded);
		}
	}, [deployment, isSettingsLoaded, isAppVersionSaved]);

	const location = useLocation();
	const showFooter = location.pathname !== '/' && !location.pathname.startsWith('/pz/') && !location.pathname.startsWith('/pm/') && !location.pathname.startsWith('/ib/');

	const lang = i18n.language;

	const isMobile = window.innerWidth < 575.98;

	return (
		<div className={styles.layout}>
			<div className={styles.mobileNavbarOverlay} style={{display: mobileNavbarOpened ? 'block' : 'none'}} onClick={() => setMobileNavbarOpened(false)}></div>
			<div className={`${styles.navbar} ${mobileNavbarOpened ? styles.navbarActive : ``}`}>
				<img className={styles.logo} src={lang === "cs" ? logo : logoEn} onClick={() => navigate('/')}/>
				{isMobile ?
					<div className={styles.menu}>
						<button className={styles.btnMenuOpen}
								onClick={() => setMobileNavbarOpened(!mobileNavbarOpened)}>
							{mobileNavbarOpened ?
								<Icon.XMark size={15} color={"#FFFFFF"}/>
								:
								<Icon.MenuIcon size={17} color={"#FFFFFF"}/>
							}
						</button>
					</div>
					:
					<div className={styles.menu}>
						<Link to={'/'} type="button" className={'btn'}>{t("menu.home")}</Link>
						<Link to={'/settings'} type="button" className={'btn'}>{t("menu.settings")}</Link>
						<LanguageSwitcher/>
					</div>
				}
			</div>
			<div className={styles.mobileNavbar} style={{display: mobileNavbarOpened ? 'flex' : 'none'}}>
				<Link
					to={'/'}
					type="button"
					className={`btn d-block ${styles.btnMobile}`}
					onClick={handleMobileMenuClick}
				>
					{t("menu.home")}
				</Link>
				<Link
					to={'/settings'}
					type="button"
					className={`btn d-block ${styles.btnMobile}`}
					onClick={handleMobileMenuClick}
				>
					{t("menu.settings")}
				</Link>
				<LanguageSwitcher imageHeight={40} className={`d-flex justify-content-center`}/>
			</div>
			<div className={getSidePanelClass()}>
				<div className={styles.sidePanelWrapper}>
					<Outlet/>
				</div>
				{showFooter && <Footer/>}
			</div>
			<AcceptCookies></AcceptCookies>
		</div>
	);
}

export default Layout;