import {useIndexedDB} from "react-indexed-db-hook";
import {useEffect, useState} from "react";

export default function useParkingSession() {
	const dbValues = useIndexedDB("parkingSession");
	const { getAll, add, deleteRecord, getByIndex: _getByIndex, clear: clearAll, getByID} = dbValues;

	const [parkingSession, setParkingSession] = useState([]);
	useEffect(() => {
		getAll()
			.then((valuesFromDB) => {
				setParkingSession(valuesFromDB);
			})
			.catch(() => setParkingSession([]));
	}, [dbValues]);

	const getByIndex = (indexName: string, key: any) => {
		if (indexName === 'id') {
			key = parseInt(key);
		}
		return parkingSession.find(parkingSession => parkingSession[indexName] === key);
	}

	const deleteByIndex = (indexName: string, key: any) => {
		if (indexName === 'id') {
			return deleteRecord(parseInt(key))
		} else {
			return _getByIndex(indexName, key).then((parkingSession) => {
                if (parkingSession && parkingSession.id) {
                    return deleteRecord(parkingSession.id)
                }

                return Promise.resolve();
            });
		}
	}

	return {parkingSession, add, deleteRecord, getByIndex, getByIndexAsync: _getByIndex, deleteByIndex, clearAll, getAll};
}