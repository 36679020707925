import React, {PropsWithChildren, useEffect, useState, useMemo, useCallback, useRef, forwardRef} from "react";
import { useField, useFormikContext } from "formik";
import Select from "react-select";
import styles from './EmailPicker.module.scss';
import { colourStyles } from "../../form/Select/Select";
import { t } from "i18next";
import useEmailsState from "../../../model/useEmailsState";
import SelectOptionEmail from "../../SelectOption/SelectOptionEmail";

type Props = {
	name: string;
	hasError: boolean;
} & PropsWithChildren & any;

const EmailPicker: React.FC<Props> = ({ name, hasError, inputRef }) => {
	const { setFieldValue, setFieldTouched, values, validateField } = useFormikContext();
	const [emails, setEmails] = useState<any[]>([]);
	const [selectedOption, setSelectedOption] = useState<any>(null);
	const [customEmail, setCustomEmail] = useState<string>('');
	const { emails: _emails } = useEmailsState();
	const [, meta] = useField(name);
	const customInputRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        if (inputRef) {
            inputRef.current = customInputRef.current;
        }
    }, [inputRef]);


    const customStyles = {
		...colourStyles,

		control: (provided: any, state: any) => ({
			...colourStyles.control(provided, state),
			backgroundColor: selectedOption?.value === 'custom' ? "#F3F3F5" : state.isFocused ? "#fff" : '#F3F3F5',
		}),
	};

	useEffect(() => {
		if (JSON.stringify(_emails) != JSON.stringify(emails)) {
			setEmails(_emails);
		}
	}, [_emails]);

	const getOptions = useMemo(() => {
		const options = emails.map((email) => ({
			value: email.email,
			label: email.email,
			isDisabled: false,
		}));

		options.push({
			value: 'custom',
			label: t('EmailPickerComponent.newEmail'),
			isDisabled: false,
		});

		return options;
	}, [emails, t]);

	const [customEmailSet, setCustomEmailSet] = useState(false);

	useEffect(() => {
        if (!emails.length) return;

		const initialValue = values[name];
		const foundEmail = emails.find((email) => email.email === initialValue);

		if (foundEmail) {
			setSelectedOption({ value: foundEmail.email, label: foundEmail.email });
            setFieldValue(name, foundEmail.email);
		} else if (initialValue) {
			setSelectedOption({ value: 'custom', label: t('EmailPickerComponent.newEmail') });
            setFieldValue(name, initialValue);
		}

		if (emails[emails.length - 1]?.email && !customEmailSet) {
			setCustomEmailSet(true);
			setSelectedOption({ value: emails[emails.length - 1]?.email, label: emails[emails.length - 1]?.email });
            setFieldValue(name, emails[emails.length - 1]?.email);
		}
	}, [emails, name, values, t]);

	const handleChange = useCallback((newValue: any) => {
		setSelectedOption(newValue);
		if (newValue?.value !== 'custom') {
			setFieldValue(name, newValue?.value || '');
			setFieldTouched(name, true, false);
			validateField(name);
		} else {
            setCustomEmail('');
			setFieldValue(name, customEmail);

            setTimeout(function () {
			    customInputRef.current?.focus();
            }, 0);
		}
	}, [name, setFieldTouched, setFieldValue, customEmail, validateField]);

	const handleInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.currentTarget.value;
		setCustomEmail(value);
		setFieldValue(name, value);
		if (value) {
			setFieldTouched(name, true, false);
		}
		validateField(name);
	}, [setFieldValue, setFieldTouched, name, validateField]);

	return (
		<div className={styles._container}>
			<Select
				options={getOptions}
				styles={customStyles}
				isSearchable={false}
				placeholder={t("buy.emailSelectPlaceholder")}
				components={{
					IndicatorSeparator: () => null,
					Option: SelectOptionEmail,
				}}
				value={selectedOption}
				name={name}
				onChange={handleChange}
			/>
			{selectedOption?.value === 'custom' && (
				<input
                    ref={(el) => {
                        customInputRef.current = el;
                        if (inputRef) inputRef.current = el;
                    }}
					className={styles.customSpz}
					value={customEmail}
					onChange={handleInputChange}
					placeholder={t('EmailPickerComponent.email')}
				/>
			)}
		</div>
	);
};

export default EmailPicker;
