import {useIndexedDB} from "react-indexed-db-hook";
import {useEffect, useState} from "react";

export default function useFavoriteZone() {
	const dbValues = useIndexedDB("favoriteZones");
	const { getAll, add, deleteRecord, getByIndex: _getByIndex, clear: clearAll, getByID} = dbValues;

	const [zones, setZones] = useState([]);
	useEffect(() => {
		getAll()
			.then((valuesFromDB) => {
				setZones(valuesFromDB);
			})
			.catch(() => setZones([]));
	}, [dbValues]);

	const getByIndex = (indexName: string, key: any) => {
		if (indexName === 'id') {
			key = parseInt(key);
		}
		return zones.find(zone => zone[indexName] === key);
	}

	const deleteByIndex = (indexName: string, key: any) => {
		if (indexName === 'id') {
			return deleteRecord(key)
		} else {
			return _getByIndex(indexName, key).then((entity) => deleteRecord(entity.id));
		}
	}

	return {zones, add, deleteRecord, getByIndex, getByIndexAsync: _getByIndex, deleteByIndex, clearAll};
}