import { useEffect, useState } from "react";
import CryptoJS from "crypto-js";

const SECRET_KEY = process.env.REACT_APP_MAP_API_KEY;

const encrypt = (data) => {
	return CryptoJS.AES.encrypt(JSON.stringify(data), SECRET_KEY).toString();
};

const decrypt = (data) => {
	const bytes = CryptoJS.AES.decrypt(data, SECRET_KEY);
	return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
};

export default function useCardsState() {
	const [cards, setCards] = useState([]);

	useEffect(() => {
		const storedCards = getSessionStorage("cards");
		if (storedCards) {
			setCards(storedCards);
		}
	}, []);

	const getSessionStorage = (name) => {
		const value = sessionStorage.getItem(name);
		return value ? decrypt(value) : null;
	};

	const setSessionStorage = (name, value) => {
		const encryptedData = encrypt(value);
		sessionStorage.setItem(name, encryptedData);
	};

	const saveToSessionStorage = (newCards) => {
		setSessionStorage("cards", newCards);
	};

	const getAll = () => {
		return new Promise((resolve) => {
			const storedCards = getSessionStorage("cards") || [];
			resolve(storedCards);
		});
	};

	const add = (newCard) => {
		const updatedCards = [...cards, newCard];
		setCards(updatedCards);
		saveToSessionStorage(updatedCards);
	};

	const deleteRecord = (id) => {
		const updatedCards = cards.filter((card) => card.id !== id);
		setCards(updatedCards);
		saveToSessionStorage(updatedCards);
	};

	const getByIndex = (indexName, key) => {
		return cards.find((card) => card[indexName] === key);
	};

	const deleteByIndex = (indexName, key) => {
		if (indexName === "id") {
			return deleteRecord(parseInt(key));
		} else {
			const card = getByIndex(indexName, key);
			if (card) {
				return deleteRecord(card.id);
			}
		}
	};

	const clearAll = () => {
		setCards([]);
		sessionStorage.removeItem("cards");
	};

	const getByCardMaskedCLN = (cardMaskedCLN) => {
		return cards.find((card) => card.cardMaskedCLN === cardMaskedCLN);
	};

	return { cards, add, deleteRecord, getByIndex, deleteByIndex, clearAll, getByCardMaskedCLN, getAll };
}
