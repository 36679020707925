export let dbVersion = 1;

export const loadDBVersion = async () => {
    try {
        const response = await fetch('/deploy.txt');
        const text = await response.text();

        dbVersion = Number(text.trim()) || 1;
    } catch (error) {
        console.error("Nepodařilo se načíst verzi DB:", error);
    }
};