import React from "react";
import styles from './Settings.module.scss';
import {useTranslation} from "react-i18next";

const websiteUrl = process.env.REACT_APP_WEB_URL;

const Gdpr = () => {

    const {t} = useTranslation();

    return (
        <div className={styles._container}>
            <div className={styles.wrapper} style={{width: 1240}}>
                <h1>{t("cookies.title")}</h1>
                <div dangerouslySetInnerHTML={{__html: t("cookies.content", {"websiteUrl": websiteUrl})}}></div>
                <div dangerouslySetInnerHTML={{__html: t("cookies.content2")}}></div>
            </div>
        </div>
    );
}

export default Gdpr;