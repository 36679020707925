import {useIndexedDB} from "react-indexed-db-hook";
import {useEffect, useState} from "react";

export default function useBuyFormValues() {
	const dbValues = useIndexedDB("buyFormValues");
	const { getAll, add, deleteRecord, getByIndex: _getByIndex, clear: clearAll, getByID} = dbValues;

	const [buyFormValues, setBuyFormValues] = useState([]);
	useEffect(() => {
		getAll()
			.then((valuesFromDB) => {
				setBuyFormValues(valuesFromDB);
			})
			.catch(() => setBuyFormValues([]));
	}, [dbValues]);

	const getByIndex = (indexName: string, key: any) => {
		if (indexName === 'id') {
			key = parseInt(key);
		}
		return buyFormValues.find(buyFormValues => buyFormValues[indexName] === key);
	}

	const deleteByIndex = (indexName: string, key: any) => {
		if (indexName === 'id') {
			return deleteRecord(parseInt(key))
		} else {
			return _getByIndex(indexName, key).then((buyFormValues) => deleteRecord(buyFormValues.id));
		}
	}

	const getLatestByDateTime = () => {
		return getAll().then(buyFormValuesFromDB => {
			return buyFormValuesFromDB
				.sort((a, b) => {
					const dateA = new Date(a.dateTime).getTime();
					const dateB = new Date(b.dateTime).getTime();
					return dateB - dateA;
				})[0];
		});
	};

	const updateByIndex = (indexName: string, key: any, updatedData: Partial<any>) => {
		if (indexName === 'id') {
			key = parseInt(key);
		}
		return _getByIndex(indexName, key).then((record) => {
			const updatedRecord = { ...record, ...updatedData };
			return deleteRecord(record.id).then(() => add(updatedRecord));
		});
	};

	return {buyFormValues, add, deleteRecord, getByIndex, getByIndexAsync: _getByIndex, deleteByIndex, clearAll, getLatestByDateTime, updateByIndex};
}