import { useEffect } from "react";
import { isSafari } from "../../model/helpers/BrowserHelper";

const useGooglePayScript = () => {
    useEffect(() => {
        if (isSafari) return;

        const scriptId = "google-pay-script";
        if (document.getElementById(scriptId)) return;

        const script = document.createElement("script");
        script.id = scriptId;
        script.src = "https://pay.google.com/gp/p/js/pay.js";
        script.async = true;
        script.onload = () => console.log("Google Pay script loaded");

        document.body.appendChild(script);

        return () => {
            const existingScript = document.getElementById(scriptId);
            if (existingScript) {
                document.body.removeChild(existingScript);
            }
        };
    }, []);
};

export default useGooglePayScript;
