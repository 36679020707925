import {useIndexedDB} from "react-indexed-db-hook";
import {useEffect, useState} from "react";

export default function useTokenSessionState() {
	const dbValues = useIndexedDB("tokenPaymentSession");
	const { getAll, add, deleteRecord, getByIndex: _getByIndex, clear: clearAll, getByID} = dbValues;

	const [tokenPaymentSession, setTokenPaymentSession] = useState([]);
	useEffect(() => {
		getAll()
			.then((valuesFromDB) => {
				setTokenPaymentSession(valuesFromDB);
			})
			.catch(() => setTokenPaymentSession([]));
	}, [dbValues]);

	const getByIndex = (indexName: string, key: any) => {
		if (indexName === 'id') {
			key = parseInt(key);
		}
		return tokenPaymentSession.find(tokenPaymentSession => tokenPaymentSession[indexName] === key);
	}

	const deleteByIndex = (indexName: string, key: any) => {
		if (indexName === 'id') {
			return deleteRecord(parseInt(key))
		} else {
			return _getByIndex(indexName, key).then((tokenPaymentSession) => deleteRecord(tokenPaymentSession.id));
		}
	}

	return {tokenPaymentSession, add, deleteRecord, getByIndex, getByIndexAsync: _getByIndex, deleteByIndex, clearAll};
}