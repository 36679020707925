export class Debugger {
    public static log(message): void {
        if (this.isEnableDebugger()) {
            console.log(message);
        }
    }

    public static error(...data: any[]): void {
        if (this.isEnableDebugger()) {
            console.error(data);
        }
    }

    public static start(): void {
        if (this.isEnableDebugger()) {
            debugger;
        }
    }

    private static isEnableDebugger() {
        const cookies = document.cookie.split(";").map((cookie) => cookie.trim());
        return cookies.find((cookie) => cookie.startsWith("debugmod=true")) !== undefined;
    }
}
