import styles from './DetailZoneItem.module.scss';
import parkingIcon from '../../assets/images/parking.svg';
import streetIcon from '../../assets/images/street.svg';
import timeIcon from '../../assets/images/time.svg';
import pricingIcon from '../../assets/images/pricing.svg';
import parkingMachine from '../../assets/images/parking-machine.svg';
import infoBoxIcon from '../../assets/images/info-solid.svg';
import {useTranslation} from "react-i18next";
import {formatTarrifPricing} from "../../services/tariffPricing";
import React, {useEffect, useState} from "react";
import {PARK_MACHINE_TYPE_FAKE} from "../Map";
import {getTextRaLevels} from "../TextRaLevels/TextRaLevels";

type DetailZoneItemProps = {
    type: string,
    value: any,
    subValue?: any,
    id?: any,
    onTogglePricingDetail?: (state: boolean) => void,
    pricingDetailState?: boolean,
    featureType?: string,
    properties: any
};

const DetailIcon = (type: string, featureType: string|null = null, properties: any) => {
    if (type === "name" && properties.parkMachineType === PARK_MACHINE_TYPE_FAKE) return infoBoxIcon;
    if (type === "name" && featureType === "parkingMachine") return parkingMachine;
    if (type === "name") return parkingIcon;
    if (type === "street") return streetIcon;
    if (type === "time") return timeIcon;
    if (type === "pricing") return pricingIcon;
};

const DetailZoneItem: React.FC<DetailZoneItemProps> = ({...props}) => {
    const {t} = useTranslation();
    let pricing;
    if (props.type === "pricing") pricing = formatTarrifPricing(props.subValue);

    const [pricingDetail, setPricingDetail] = useState(props.pricingDetailState || false);

    useEffect(() => {
        setPricingDetail(false);
    }, [props.id]);

    useEffect(() => {
        if (props.pricingDetailState !== undefined) {
            setPricingDetail(props.pricingDetailState);
        }
    }, [props.pricingDetailState]);

    const handlePricingDetailToggle = () => {
        const newState = !pricingDetail;
        setPricingDetail(newState);
        if (props.onTogglePricingDetail) {
            props.onTogglePricingDetail(newState);
        }
    };

    const [textRaLevels, setTextRaLevels] = useState<string>();
    useEffect(() => {
        const fetchRaLevels:() => Promise<void> = async () => {
            setTextRaLevels(await getTextRaLevels(props.properties.sectionID));
        };
        fetchRaLevels();
    }, [props.properties.sectionID]);

    return (
        <div className={styles.item}>
            <div className={styles.info}>
                <div className={styles.icon}>
                    <img src={DetailIcon(props.type, props.featureType, props.properties)} height={24} alt={''} />
                </div>
                <div className={`text-nowrap ${styles.title}`} style={{ display: "flex", justifyContent: "space-between" }}>
                    <div className={styles.placeName}>
                        {(props.type === "pricing") ? props.subValue.activeTariffGroups[0].activeTariffDefinitions[0].pricePerHour : ''}
                        {(props.type === "time") ? ((props.properties.tariffInfo.activeTariffGroups?.[0]?.activeTariffDefinitions?.[0]?.minuteTo / 60)) : ''}
                        {(props.type !== "time" && props.type !== "pricing") ? props.value : ''}

                        {(props.type === "time") ? " " + t("home.DetailZoneItemComponent.hour") : ""}
                        {(props.type === "pricing") ? " " + t("global.currency.czk") + " / " + t("global.hour") : ""}
                    </div>
                </div>
                {(props.type === "name") ? (
                    <div className={`align-items-center ${styles.title}`}>
                        <div className={styles.placeName}>{textRaLevels}</div>
                    </div>
                ) : null}

                {(props.type !== 'pricing' || (props.type === 'pricing' && props.subValue?.maxHour > 0)) && (
                    <div className={styles.textEnd}>
                        {(props.type === "name") && (
                            <span className={styles.textMuted}>
                                {props.properties.parkMachineType === PARK_MACHINE_TYPE_FAKE ? t("home.MapSearchComponent.zones.infoBox") : (
                                    props.featureType === "parkingZone"
                                        ? (props.subValue ?? '')
                                        : (props.value.length <= 7
                                            ? t("home.MapSearchComponent.zones.parkingMachine")
                                            : t("home.MapSearchComponent.zones.parkingMachineShort")
                                        )
                                )}
                            </span>
                        )}

                        {(props.type === "time") && (
                            <span className={styles.textMuted}>{t("home.DetailZoneItemComponent.maxTime")}</span>
                        )}

                        {(props.type === "pricing" && props.subValue?.maxHour > 0) && (
                            <span onClick={handlePricingDetailToggle} className={`${styles.textPricing} ${pricingDetail ? styles.arrowActive : ``}`}>
                                {t("home.DetailZoneItemComponent.pricing")}
                                <svg width="16" height="9" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8 9C7.71875 9 7.46875 8.90625 7.28125 8.71875L1.28125 2.71875C0.875 2.34375 0.875 1.6875 1.28125 1.3125C1.65625 0.90625 2.3125 0.90625 2.6875 1.3125L8 6.59375L13.2812 1.3125C13.6562 0.90625 14.3125 0.90625 14.6875 1.3125C15.0938 1.6875 15.0938 2.34375 14.6875 2.71875L8.6875 8.71875C8.5 8.90625 8.25 9 8 9Z" fill="#1D4F8B"/>
                                    <path d="M8 9C7.71875 9 7.46875 8.90625 7.28125 8.71875L1.28125 2.71875C0.875 2.34375 0.875 1.6875 1.28125 1.3125C1.65625 0.90625 2.3125 0.90625 2.6875 1.3125L8 6.59375L13.2812 1.3125C13.6562 0.90625 14.3125 0.90625 14.6875 1.3125C15.0938 1.6875 15.0938 2.34375 14.6875 2.71875L8.6875 8.71875C8.5 8.90625 8.25 9 8 9Z" fill="black" fillOpacity="0.2"/>
                                </svg>
                            </span>
                        )}
                    </div>
                )}

                {(props.type === "pricing") && (
                    props.subValue?.maxHour > 0 ? (
                        <div className={`${styles.pricingDetail} ${pricingDetail ? styles.pricingDetailActive : ``}`}>
                            {pricing.map((object, i2) =>
                                <div key={i2}>
                                    {object.detail.map((el, i) =>
                                        <table className={styles.table} key={i}>
                                            <tbody>
                                        <tr>
                                            <td className={styles.tableDay}>{i === 0 ? <span>{t(`global.daysShort.${object.firstDay}`)}{object.lastDay ? "-" + t(`global.daysShort.${object.lastDay}`) : ""}</span> : null}</td>
                                            <td className={styles.tableHours}>{el.string}</td>
                                            <td className={styles.tablePrice}>{object.firstDay === "holiday" ? t('global.max') : ""} {el.price} {t("global.currency.czk")} / {object.firstDay === "holiday" ? t("global.day") : t("global.hour")}</td>
                                        </tr>
                                            </tbody>
                                        </table>
                                    )}
                                </div>
                            )}
                        </div>
                    ) : (
                        <span className={styles.textMuted}>{t("home.DetailZoneItemComponent.onlyForResidents")}</span>
                    )
                )}
            </div>
        </div>
    );
}

export default DetailZoneItem;