
import { CzechHolidays, HolidayDate } from "czech-holidays";

const date:Date = new Date();
const holidays: HolidayDate[] = CzechHolidays(date.getFullYear());

export const isHoliday = (currentDate):boolean => {
    const currentDay = currentDate.day();
    const currentMonth = currentDate.month();

    return holidays.some((el) => {
        return el.d === currentDay && el.m === currentMonth;
    });
}
