import React, {useEffect, useState} from "react";
import savedStyles from '../Settings.module.scss';
import {Link, useNavigate} from "react-router-dom";
import Icon from "../../components/Icon";
import styles from "../Settings.module.scss";
import {Field, Form, Formik, useFormikContext} from "formik";
import useVehiclesState from "../../model/useVehiclesState";
import {useTranslation} from "react-i18next";
import * as Yup from "yup";
import {CheckLicencePlate} from "../../model/helpers/CheckLicencePlate";

interface FormValues {
	[key: string]: string;
}

const NewVehicle = () => {
	const { vehicles, add, deleteByIndex } = useVehiclesState();
	const navigate = useNavigate();
	const {t} = useTranslation();

	const initialValues = {
		spz: '',
		name: ''
	};

	const validationSchema = Yup.object().shape({
		spz: Yup.string().required().min(1).max(10),
		name: Yup.string().required().max(22)
	});

	const handleSubmit = (values) => {
		if(values.spz && values.name) {
            let vehicleExists = false;
            
            vehicles.map((vehicle, key) => {
                if (!vehicleExists && vehicle.spz === values.spz) {
                    vehicleExists = true;
                }
            })

            // nejdříve smažeme a následně uložíme, protože se mohlo změnit pojmenování
            if (vehicleExists) {
                deleteByIndex('spz', values.spz)
                    .then(() => {
                        add(values);
                    })
            } else {
                add(values);
            }

			navigate('/settings');
		}
	}

	const [disabledButton, setDisabledButton] = useState(true);
	const [hasLicencePlateWarning, setHasLicencePlateWarning] = useState(false);

	const FormikUseEffect = () => {
		const {values, errors, touched, isValid, setValues} = useFormikContext<FormValues>();
		useEffect(() => {
			setDisabledButton(!isValid || !touched.spz || !touched.name);
		}, [values, errors, touched, isValid]);

		useEffect(() => {
			if (CheckLicencePlate(values.spz) && !errors.spz) {
				setHasLicencePlateWarning(true);
			} else {
				setHasLicencePlateWarning(false);
			}
		}, [values.spz, hasLicencePlateWarning]);

		useEffect(() => {
			if (values.spz) {
				const cleanedSpz = values.spz
					.replace(/[\s\-–]/g, '')
					.toUpperCase()
					.replace(/[^A-Z0-9]/g, '');

				if (cleanedSpz !== values.spz) {
					setValues({ ...values, spz: cleanedSpz });
				}
			}
		}, [values.spz]);

		return null;
	}

	return (
		<Formik
			initialValues={initialValues}
			onSubmit={handleSubmit}
			validationSchema={validationSchema}
		>
			{({ errors, touched , setFieldTouched}) => (

				<Form className={savedStyles._container}>
				<FormikUseEffect />
				<div className={savedStyles.wrapper}>
					<Link to={'/settings'} className={`d-flex-center gap-1 ${savedStyles.backLink}`}>
						<Icon.LeftChevron size={12} />
						{t("settings.newVehicle.back")}
					</Link>
					<h1>{t("settings.newVehicle.title")}</h1>
					<div className={styles.itemGroups}>
						<div className={styles.itemGroup}>
							<div className={`${styles.inputItem} ${errors.spz && touched.spz ? styles.inputError : ''} ${hasLicencePlateWarning ? styles.inputWarning : ''}`}>
								<div className={styles.labelIcon}>
									<Icon.Car size={22} color={errors.spz && touched.spz ? "#CC1818" : "" || hasLicencePlateWarning ? "#ffa340" : "#1D4F8B"}/>
								</div>
								<div className={styles.cardContent}>
									<div>{t("settings.newVehicle.licencePlate")}</div>
								</div>
								<div className={styles.input}>
									<Field
										name={'spz'}
										type={'text'}
										maxLength={10}
										onInput={() => setFieldTouched('spz', true, true)}
										placeholder={t("settings.newVehicle.licencePlatePlaceholder")}
									/>
								</div>
							</div>
							<div className={`${styles.inputItem} ${errors.name && touched.name ? styles.inputError : ''}`}>
								<div className={styles.labelIcon}>
									<Icon.Car size={22} color={errors.name && touched.name ? "#CC1818" : "#1D4F8B"}/>
								</div>
								<div className={styles.cardContent}>
									<div>{t("settings.newVehicle.name")}</div>
								</div>
								<div className={styles.input}>
									<Field
										name={'name'}
										maxLength={22}
										type={'text'}
										onInput={() => setFieldTouched('name', true, true)}
										placeholder={t("settings.newVehicle.namePlaceholder")}
									/>
								</div>
							</div>
						</div>
						{(errors.spz && touched.spz || errors.name && touched.name) &&
							<div className={styles.inputErrorColor}>
								<p>{t('settings.newEmail.error')}</p>
							</div>
						}
						{(hasLicencePlateWarning) &&
							<div className={styles.inputErrorColor}>
								<p>{t('global.licencePlateWarning')}</p>
							</div>
						}
					</div>
					<button disabled={disabledButton} type={'submit'} className={`btn btn-primary ${savedStyles.submitButton}`}>
						<Icon.Check size={16} color={"#FFFFFF"} />
						{t("settings.newVehicle.save")}
					</button>
				</div>
			</Form>
			)}
		</Formik>
	);
}

export default NewVehicle;