import React, { useEffect, useState } from "react";
import {useTranslation} from "react-i18next";
import styles from './AcceptCookies.module.scss';

const AcceptCookies = () => {
    const { t } = useTranslation();
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const consentGiven = localStorage.getItem("acceptCookies");
        if (!consentGiven) {
            setIsVisible(true);
        }
    }, []);

    const handleAccept = () => {
        localStorage.setItem("acceptCookies", "true");
        setIsVisible(false);
    };

    if (!isVisible) return null;

    return (
        <div className={styles.modalOverlay}>
            <div className={styles.modalContent}>
                <div className={styles.modalHeader}>
                    <h2>{t("cookies.acceptTitle")}</h2>
                </div>
                <div className={styles.modalBody}>
                    <p dangerouslySetInnerHTML={{ __html: t("cookies.acceptText") }}></p>
                </div>
                <button
                    className={styles.modalButton}
                    onClick={handleAccept}
                >
                    {t("cookies.acceptButton")}
                </button>
            </div>
        </div>
    );
};

export default AcceptCookies;
