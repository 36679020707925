import i18n from "i18next";
import i18nBackend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

const languageDetector = new LanguageDetector();

const getInitialLanguage = () => {
    const savedLanguage = document.cookie.split('; ').find(row => row.startsWith('lang='));
    if (savedLanguage) {
        return savedLanguage.split('=')[1];
    }
    return "cs";
};

const supportedLanguages = ["en", "cs"];
const fallbackLanguage = "cs";

i18n
    .use(i18nBackend)
    .use(initReactI18next)
    .use(languageDetector)
    .init({
        detection: {
            order: ["cookie"],
            lookupCookie: 'lang',
            convertDetectedLanguage: (lng) => lng.split('-')[0]
        },
        lng: getInitialLanguage(),
        fallbackLng: fallbackLanguage,
        supportedLngs: supportedLanguages,
        interpolation: {
            escapeValue: false,
        },
        backend: {
            loadPath: `${window.location.origin}/lang/{{lng}}.json`,
        },
    });

export default i18n;
